/* eslint-disable no-mixed-spaces-and-tabs */
export const storeData = {
	'atlanta': {
		'location': {
			'title': {
				'suffix': '',
				'text': 'Atlanta',
			},
			'breadcrumbID': 301,
			'imageName': 'atlanta_ext_0821',
			'curalateLabel': 'atlanta',
			'hashTag': 'roomandboardAtlanta',
			'jrni': 'Atlanta',
			'hours': {
				'range': [
					{
						'days': 'Monday – Saturday',
						'times': '10 a.m. – 6 p.m.',
					},
					{
						'days': 'Sunday',
						'times': '12 p.m. – 5 p.m.',
					},
				],
			},
			'about': {
				'gallery': {
				   'images': [
						{
							'altText': 'Detail of yellow chair in small living room at Room &amp; Board Atlanta',
							'caption': 'Visit Room & Board Atlanta in the Westside Provisions District. Our showroom is filled with living room, dining room, bedroom, kids’ room settings and more so you can find inspiration for every room of your home.',
							'fileName': 'atlanta_interior_09e5_g',
					  },
					  {
							'altText': 'Detail of large sectional at Room &amp; Board Atlanta',
							'caption': 'Room & Board creates furniture and home decor with an emphasis on enduring design movements from the past, updated for today’s modern lifestyle.',
							'fileName': 'atlanta_interior_09e7_g',
					  },
					  {
							'altText': 'Detail of outdoor furniture at Room &amp; Board Atlanta',
							'caption': 'Whether you’re shopping for office furniture or outdoor patio furniture, you can be sure Room & Board products are made to last a lifetime. More than 90 percent of our products are made in America using the highest quality U.S. and imported materials.',
							'fileName': 'atlanta_interior_09e1_g',
					  },
					  {
							'altText': 'Detail of large living room at Room &amp; Board Atlanta',
							'caption': 'Find what furniture and home decor speaks to you as you walk through Room & Board Atlanta. Our Design Associates are readily available to help with item selection, recommend materials and paint colors - just ask us about our free design services.',
							'fileName': 'atlanta_interior_09e_g',
					  },
					  {
							'altText': 'Detail of velvet sofa and armchair at Room &amp; Board Atlanta',
							'caption': 'Stop into the showroom today to get your modern design inspiration going!',
							'fileName': 'atlanta_interior_09e2_g',
					  },
					],
				},
			},
			'contact': {
				'phone': '877.389.9707',
				'email': 'atlanta@roomandboard.com',
			},
			'address': {
				'title': 'Room & Board Atlanta',
				'subTitle': 'Westside Provisions District',
				'streetAddress': '1170 Howell Mill Road NW',
				'city': 'Atlanta',
				'state': 'GA',
				'zipCode': '30318',
				'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@33.7860157,-84.4141843,17z/data=!3m1!4b1!4m5!3m4!1s0x88f504ec74086805:0xd0a8e9ffb83368d8!8m2!3d33.7860113!4d-84.4119956',
				'mapImageName': 'atlanta_map_0821',
				'parkingText': 'Enjoy two hours of free validated parking at Westside Provisions District and a $3 discount on additional hours. To access our elevator, follow the handicap-accessible ramp to the left of the store entrance.',
			},
			'virtualTourLink': {
				'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x88f504ec74086805%3A0xd0a8e9ffb83368d8!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D102254415197888954026%26id%3D6422078473172250210%26target%3DPHOTO&imagekey=!1e2!2sAVTZtE8wBmsAAAQWtM60Qw&sa=X&ved=0ahUKEwid0YTH9ObXAhXJ64MKHZIHAOoQoioIhwEwCg&activetab=panorama',
			},
			'schema': {
				'openingHours': ['Mo-Sa 10:00-18:00', 'Su 12:00-17:00'],
			},
			'key': 'atlanta',
			'deliveryCenter': 'atlanta',
		},
	},
	'austin': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Austin',
		   },
		   'metaDescription': 'Shop modern furniture and home decor at Room & Board Austin, a modern furniture store in Domain NORTHSIDE.',
		   'breadcrumbID': 902,
		   'imageName': 'austin_ext_0322',
		   'curalateLabel': 'austin',
		   'hashTag': 'roomandboardAustin',
		   'jrni': 'Austin',
		   'hours': {
			  'range': [
					{
						'days': 'Monday – Friday',
						'times': '11 a.m. – 7 p.m.',
				 	},
				 	{
						'days': 'Saturday',
						'times': '10 a.m. – 6 p.m.',
			 		},
				 	{
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 	},
			  	],
		   },
		   'contact': {
			  'phone': '877.383.1017',
			  'email': 'Austin@roomandboard.com',
		   },
		   'address': {
				'title': 'Room & Board Austin',
				'subTitle': 'Domain NORTHSIDE',
			  	'streetAddress': '3200 Palm Way, Suite 170',
			  	'city': 'Austin',
			  	'state': 'TX',
			  	'zipCode': '78758',
			  	'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@30.4032451,-97.7236594,17z/data=!4m5!3m4!1s0x8644cd80ad14e667:0x3718302a1b7b06e1!8m2!3d30.4033661!4d-97.7235532',
			  	'mapImageName': 'austin_map_1121',
			  	'parkingText': 'Enjoy free parking in any of five onsite parking garages: Blue (closest to our store), Red, Green, Purple and Grey.',
		   },
		   'virtualTourLink': {
				'cdata': 'https://www.google.com/maps/@30.4033408,-97.7234273,3a,75y,72.28h,88.62t/data=!3m6!1e1!3m4!1sAF1QipMFTdiMHucAaAjsRVthrk9LeMhudtgc3kcSrDdF!2e10!7i12000!8i6000?shorturl=1',
		 	},
		   'schema': {
			  'openingHours': ['Mo-Fr 11:00-19:00', 'Sa 10:00-18:00', 'Su 11:00-18:00'],
		   },
		   'key': 'austin',
		},
	 },
	 'bethesda': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Bethesda',
		   },
		   'breadcrumbID': 451,
		   'imageName': 'bethesda_ext_113021_v3',
		   'curalateLabel': 'bethesda',
		   'hashTag': 'roomandboardBethesda',
		   'jrni': 'Bethesda',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Saturday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '866.407.6252',
			  'email': 'bethesda@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Bethesda',
			  'subTitle': 'Bethesda Row',
			  'streetAddress': '7236 Woodmont Avenue',
			  'city': 'Bethesda',
			  'state': 'MD',
			  'zipCode': '20814',
			  'mapLink': 'https://maps.google.com/maps?cid=9678666698547875572',
			  'mapImageName': 'bethesda_map_0821',
			  'parkingText': 'Enjoy free public garage and lot parking on Saturdays, Sundays and holidays. Street parking is free on Sundays and holidays.',
			  'publicTransitText': {
				 'publicTransitOption': [
						'Red Metrorail: to Bethesda Metro stop',
				 ],
			  },
		   },
		   'virtualTourLink': {
				'cdata': 'https://www.google.com/maps/place/Room+%26+Board/@38.9814914,-77.0962362,3a,75y,340h,90t/data=!3m8!1e1!3m6!1sAF1QipOUMoztEpt67ut1lnZna553uMNnU63NtZZGVKOw!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipOUMoztEpt67ut1lnZna553uMNnU63NtZZGVKOw%3Dw203-h100-k-no-pi0-ya338.4-ro-0-fo100!7i8000!8i4000!4m7!3m6!1s0x0:0x8651881522594ef4!8m2!3d38.981486!4d-77.0960571!14m1!1BCgIgARICCAI',
		 	},
		   'schema': {
			  'openingHours': ['Mo-Sa 11:00-19:00', 'Su 11:00-18:00'],
		   },
		   'key': 'bethesda',
		   'deliveryCenter': 'washingtonDc',
		   'pickupId': '1650',
		},
	 },
	'boston': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Boston',
		   },
		   'breadcrumbID': 501,
		   'imageName': 'boston_ext_0821',
		   'curalateLabel': 'boston',
		   'hashTag': 'roomandboardBoston',
		   'jrni': 'Boston',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Saturday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '877.396.3066',
			  'email': 'boston@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Boston',
			  'subTitle': 'Located at Newbury and Mass Ave.',
			  'streetAddress': '375 Newbury Street',
			  'city': 'Boston',
			  'state': 'MA',
			  'zipCode': '02115',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@42.3482435,-71.0907041,17z/data=!3m1!4b1!4m5!3m4!1s0x89e37a04d5f146dd:0xdb26ab35d9cd80b2!8m2!3d42.3482396!4d-71.0885154',
			  'mapImageName': 'boston_map_0821',
			  'parkingText': 'Nearby parking is available at the Hynes Convention Center garage at 50 Dalton Street or the Prudential Center garage at 79-99 Exeter Street.<br/><a class=\'Body Body--1\' href=\'https://www.google.com/maps/place/50+Dalton+St,+Boston,+MA+02115/@42.3470637,-71.0878475,17z/data=!3m1!4b1!4m5!3m4!1s0x89e37a1aa902ea07:0xa05335ec974a9a96!8m2!3d42.3470598!4d-71.0856588\' target=\'_blank\'>Get directions to Hynes Parking</a><br/><a class=\'Body Body--1\' href=\'https://www.google.com/maps/place/Prudential+Center+Garage/@42.3484259,-71.0813519,17z/data=!3m1!4b1!4m5!3m4!1s0x89e37a0dd14c2081:0xc14b39c13eeb76a!8m2!3d42.348422!4d-71.0791632\' target=\'_blank\'>Get directions to Prudential Parking</a>',
			  'publicTransitText': {
				 'publicTransitOption': [
						'Green line via B, C and D lines: We’re across the street from the Hynes Convention Center T stop',
				 ],
			  },
		   },
		   'about': {
			  'gallery': {
				 'images': [
						{
					   'altText': 'Detail of small living space next to staircase at Room & Board Boston',
					   'caption': 'Room & Board Boston is conveniently located at Newbury Street and Massachusetts Avenue. See all the modern furniture and home decor our five-floor showroom has to offer.',
					   'fileName': 'boston_int_1_g',
						},
						{
					   'altText': 'Detail of pillow and lighting selection at Room & Board Boston',
					   'caption': 'Each floor offers inspiration for living rooms, bedrooms, dining rooms, offices and more. You can easily find home design inspiration for every room in your own house. (Plus, views of Boston and Fenway Park are inspirational, too!)',
					   'fileName': 'boston_int_3_g',
						},
						{
					   'altText': 'Detail of large open living, dining and bedroom space at Room & Board Boston',
					   'caption': 'Our Design Associates are experts at combining Room & Board’s modern furniture with the traditional architecture of Boston homes, and they’re especially skilled at helping select items for small spaces.',
					   'fileName': 'boston_int_7_g',
						},
						{
					   'altText': 'Detail of outdoor furniture next to window at Room & Board Boston',
					   'caption': 'From indoor to outdoor, you’ll love the furniture and home decor at Room & Board Boston. We craft our items with an emphasis on sustainability, focusing on design elements from the past that are updated for today’s modern lifestyle.',
					   'fileName': 'boston_int_5_g',
						},
						{
					   'altText': 'Detail of large wood dining table at Room & Board Boston',
					   'caption': 'You’ll love the look of natural materials, clean lines and a mix of looks in your home. See how we put them all together at our Boston showroom.',
					   'fileName': 'boston_int_6_g',
						},
						{
					   'altText': 'Detail of leather sofa and leather daybed at Room & Board Boston',
					   'caption': 'Love the look of mixing different furniture and home decor but aren’t totally sure how to get the best, personalized look? Room & Board Boston offers free design services. Request an appointment online or stop into the store today.',
					   'fileName': 'boston_int_8_g',
						},
						{
					   'altText': 'Detail of small living space next to fireplace at Room & Board Boston',
					   'caption': 'Whether mid-century or purely modern styles speak to you, we have furniture that’s made to last. You won’t find higher quality furniture at a better value anywhere.',
					   'fileName': 'boston_int_9_g',
						},
						{
					   'altText': 'Detail of large blue velvet sofa at Room & Board Boston',
					   'caption': 'We believe in helping you create a home you love. If you need to fill an entire apartment with furniture or just find the last throw pillow to complete a room, Room & Board Boston shows a wide range of styles and materials to work with your lifestyle.',
					   'fileName': 'boston_int_11_g',
						},
						{
					   'altText': 'Detail of Kids’ department at Room & Board Boston',
					   'caption': 'Check out our lower level for kids’ room ideas. Cribs, bunks and lofts, twin and full size beds are all made to the same high standard as our other furniture. Each piece is made to last and complements the other items in your home.',
					   'fileName': 'boston_int_4_g',
						},
						{
					   'altText': 'Detail of counter-height table at Room & Board Boston',
					   'caption': 'Furniture and home décor selection, paint and material suggestions, and layout ideas are all just the start of what our Design Associates can help you with. Our free design services are personalized to your style, needs and budget.',
					   'fileName': 'boston_int_2_g',
						},
						{
					   'altText': 'Detail of entrance and floor level signs at Room & Board Boston',
					   'caption': 'Visit Room & Board today to see all of our furniture and home decor in a beautiful Boston setting.',
					   'fileName': 'boston_int_10_g',
						},
				 ],
			  },
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x89e37a04d5f146dd%3A0xdb26ab35d9cd80b2!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D102119050734171212729%26id%3D6461347143874064130%26target%3DPHOTO&imagekey=!1e10!2sAF1QipMoyAcWCbRZ9FZh3L1IdCSpLVK3ulNAMuXwIwY4&sa=X&ved=0ahUKEwiTnfyJ9ebXAhUp6IMKHeuiBbQQoioIkwEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Sa 11:00-19:00', 'Su 11:00-18:00'],
		   },
		   'key': 'boston',
		   'deliveryCenter': 'boston',
		   'pickupId': '1631',
		},
	 },
	 'chelsea': {
		'location': {
		   'title': {
			  'suffix': ' - Chelsea',
			  'text': 'New York City',
		   },
		   'breadcrumbID': 701,
		   'imageName': 'chelsea_ext_0821',
		   'curalateLabel': 'new_york_city_chelsea',
		   'hashTag': 'roomandboardChelsea',
		   'jrni': 'Chelsea',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Saturday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '866.396.1055',
			  'email': 'chelsea@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board New York City',
			  'subTitle': 'Chelsea',
			  'streetAddress': '236 West 18th Street',
			  'city': 'New York',
			  'state': 'NY',
			  'zipCode': '10011',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@40.7415222,-74.0020715,17z/data=!3m1!4b1!4m5!3m4!1s0x89c259bc115bf543:0xcb6707a3b8a2673a!8m2!3d40.7415182!4d-73.9998828',
			  'mapImageName': 'chelsea_map_0821',
			  'publicTransitText': {
				 'publicTransitOption': [
						'(A), (C), (E), (L), (2), (3), (F), (M)',
						'NJ Path train: to 14th Street',
						'(1): to 18th Street',
				 ],
			  },
		   },
		   'about': {
			  'gallery': {
				 'images': [
						{
							'altText': 'Detail of small living spaces at Room & Board New York City',
							'caption': 'Visit one of the Room & Board flagship stores in New York City’s Chelsea neighborhood. The expansive showroom shows off the best of modern home design.',
							'fileName': 'chelsea_interior_16e8_g',
					  	},
					  	{
							'altText': 'Detail of lounge chairs next to staircase at Room & Board New York City',
							'caption': 'You’ll love seeing full room settings with our modern furniture and home decor. We specifically craft our furniture in a neutral palette and nature-inspired colors and textures to create a cohesive feel.',
							'fileName': 'chelsea_interior_16e9_g',
					 	},
					  	{
							'altText': 'Detail of velvet sofas next to dining area at Room & Board New York City',
							'caption': 'No matter your space, style or budget, our design services are always available to you for free. Design Associates are masters at selecting products for your home and arranging your furniture in a personal, functional way.',
							'fileName': 'chelsea_interior_16e6_g',
					  	},
					  	{
							'altText': 'Detail of large white sectional at Room & Board New York City',
							'caption': 'Explore our fabrics, leathers, rugs, woods and more to find what’s best for you. Room & Board is selective about each material we introduce so that it’s not only beautiful, but also will keep up with your modern lifestyle.',
							'fileName': 'chelsea_interior_16e7_g',
					  	},
					  	{
							'altText': 'Detail of adjacent dining, living and bedroom areas at Room & Board New York City',
							'caption': 'Trying to space plan for a small New York apartment? We’ve got you covered. Check out our store for small-space furniture and products, and our Design Associates can show you the best furniture arrangements and layouts to make the most of your space.',
							'fileName': 'chelsea_interior_16e4_g',
					  	},
					  	{
							'altText': 'Detail of clean, modern living room at Room & Board New York City',
							'caption': 'A mix of classically inspired styles like mid-century, Shaker, Arts & Crafts and more can be found throughout Room & Board New York City.',
							'fileName': 'chelsea_interior_16e5_g',
					  	},
					  	{
							'altText': 'Detail of dining table and chairs next to home decor options at Room & Board New York City',
							'caption': 'Whether you’re furnishing an apartment or are seeking the perfect host or hostess gift, look no further than Room & Board. More than 90 percent of our items are crafted in America of the highest quality U.S. and imported materials.',
							'fileName': 'chelsea_interior_16e2_g',
					  	},
					  	{
							'altText': 'Detail of bedroom area next to home decor selection at Room & Board New York City',
							'caption': 'Wondering which patterns go together or how to find the right size rug for your space? Get ideas and inspiration as you browse the showroom or ask one of our helpful Design Associates are you’re perusing the store.',
							'fileName': 'chelsea_interior_16e3_g',
					  	},
					  	{
							'altText': 'Detail of outdoor lounge furniture area at Room & Board New York City',
							'caption': 'Add another room to your small space by maximizing outdoor areas. Patio and outdoor furniture for balconies, patios and decks is on display at Room & Board Chelsea. Our outdoor furniture look just as beautiful as our indoor furniture and can weather any elements.',
							'fileName': 'chelsea_interior_16e_g',
					  	},
					  	{
							'altText': 'Detail of Kids’ and Baby furniture options at Room & Board New York City',
							'caption': 'Furnish a baby or kid’s room with modern items. Check out our full kids’ room displays with furniture and home decor that’s made to the same high standard as all other Room & Board furniture.',
							'fileName': 'chelsea_interior_16e1_g',
					  	},
				 ],
			  },
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x89c259bc115bf543%3A0xcb6707a3b8a2673a!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D102119050734171212729%26id%3D6112559044436343170%26target%3DPHOTO&imagekey=!1e10!2sAF1QipNRjqYsWSs2CfmTJR1G6U5aAFVt4-TcdOqnafFP&sa=X&ved=0ahUKEwjWqL309ebXAhVL7oMKHQw3BJQQoioIiAEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Sa 11:00-19:00', 'Su 11:00-18:00'],
		   },
		   'key': 'chelsea',
		   'deliveryCenter': 'newYorkArea',
		   'pickupId': '1630',
		},
	 },
	'chicago': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Chicago',
		   },
		   'breadcrumbID': 401,
		   'imageName': 'chicago_ext_0821',
		   'curalateLabel': 'chicago_downtown',
		   'hashTag': 'roomandboardChicago',
		   'jrni': 'Chicago',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Friday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Saturday',
						'times': '10 a.m. – 6 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'about': {
			   'gallery': {
					'images': [
						{
							'altText': 'Detail of coordinated dining, living and bedroom area at Room &amp; Board Chicago',
							'caption': 'Our Design Associates are experts at pairing our modern furniture and home decor with the architecture of Chicago, so whether you live in a condo, house or small studio apartment, we have furniture layout ideas and product recommendations personalized for you.',
							'fileName': 'chicago_int_3_g',
						},
						{
							'altText': 'Detail of adjacent bedroom and dining area at Room &amp; Board Chicago',
							'caption': 'More than 90 percent of Room & Board products are made in America using the highest quality U.S. and imported materials. This allows us to feature beautiful, sustainably made furniture in our showroom - each piece with a story you’d be proud to share.',
							'fileName': 'chicago_int_5_g',
						},
						{
							'altText': 'Detail of armchair and small bookcase at Room &amp; Board Chicago',
							'caption': 'Room & Board Chicago is full of design inspiration for any room – including small spaces. Visit the third floor Small Space department to find the best solutions for micro-living.',
							'fileName': 'chicago_int_8_g',
						},
						{
							'altText': 'Detail of small living room with small sectional at Room &amp; Board Chicago',
							'caption': 'Each room in the Small Space department highlights smart use of a tight floor plan. Browse by square footage to make shopping even simpler.',
							'fileName': 'chicago_int_9_g',
						},
						{
							'altText': 'Detail of wood furniture in bedroom at Room &amp; Board Chicago',
							'caption': 'You’ll find designs throughout the showroom that highlight warm, modern design.',
							'fileName': 'chicago_int_10_g',
						},
						{
							'altText': 'Detail of sleeper sofa at Room &amp; Board Chicago',
							'caption': 'From small space sleeper sofas to sprawling sectionals, you’ll find a wide variety of furniture throughout three floors of showroom inspiration.',
							'fileName': 'chicago_int_11_g',
						},
						{
							'altText': 'Detail of small dining area at Room &amp; Board Chicago',
							'caption': 'From multi-use products to out-of-the-ordinary solutions, our Design Associates are experts at maximizing spaces – small or large.',
							'fileName': 'chicago_int_12_g',
						},
						{
							'altText': 'Detail of small bed with reading light at Room &amp; Board Chicago',
							'caption': 'While you’re in the Small Space department, keep an eye out for tiny tips! Each one is packed for a helpful tip for small space living.',
							'fileName': 'chicago_int_13_g',
						},
						{
							'altText': 'Detail of adjacent living room areas at Room &amp; Board Chicago',
							'caption': 'You’ll find modern furniture and home decor at the Room & Board Chicago store in the heart of downtown.',
							'fileName': 'chicago_int_1_g',
						},
						{
							'altText': 'Detail of frame wall next to living area at Room &amp; Board Chicago',
							'caption': 'Finishing a frame wall? Wondering which dining chairs look best with your table? What size rug should you get? Our Design Associates are always available to help with home design questions. In fact, our design services are free so get started on your project today!',
							'fileName': 'chicago_int_7_g',
						},
						{
							'altText': 'Detail of two green armchairs in living room at Room &amp; Board Chicago',
							'caption': 'Our expansive showroom displays room settings for every room in a home so inspiration is endless and you can see how our products would work in everyday life.',
							'fileName': 'chicago_int_2',
						},
						{
							'altText': 'Detail of Kids’ department at Room &amp; Board Chicago',
							'caption': 'Furnishing kids’ rooms can be tricky - that’s why we use the same high-quality materials and construction techniques in every piece of Room & Board furniture. Explore kids’ furniture that’s built to last and complements the rest of your home.',
							'fileName': 'chicago_int_4',
						},
					],
				},
		 	},
		   'contact': {
			  'phone': '877.396.4068',
			  'email': 'chicago@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Chicago',
			  'subTitle': 'Downtown at Rush and Ohio',
			  'streetAddress': '55 East Ohio Street',
			  'city': 'Chicago',
			  'state': 'IL',
			  'zipCode': '60611',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@41.8922693,-87.6278539,17z/data=!3m1!4b1!4m5!3m4!1s0x880e2cac545fcf19:0x9da1537994ae9234!8m2!3d41.8922653!4d-87.6256652',
			  'mapImageName': 'chicago_map_0821',
			  'parkingText': 'Enjoy three hours of free validated parking at the Rush-Ohio-Wabash (ROW) Self Park at 50 East Ohio Street or the Ohio-Grand-Wabash Self Park at 10 East Grand Avenue. Present your parking ticket to a Design Associate for validation. <br/><a class="Body Body--1" href="https://www.google.com/maps/place/InterPark/@41.8927255,-87.6281967,17z/data=!3m1!4b1!4m5!3m4!1s0x880e2cad001ab64b:0xeeb7be349b1697c0!8m2!3d41.8927215!4d-87.626008" target="_blank">Get directions to ROW parking</a><br/><a classs="Body Body--1" href="https://www.google.com/maps/place/10+E+Grand+Ave,+Chicago,+IL+60611/@41.8920156,-87.6297282,17z/data=!3m1!4b1!4m5!3m4!1s0x880e2cad971620f5:0xdae44dd413265e89!8m2!3d41.8920116!4d-87.6275395" target="_blank">Get directions to Ohio-Grand-Wabash parking</a>',
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x880e2cac545fcf19%3A0x9da1537994ae9234!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D115601826485646914744%26id%3D6429786476172268226%26target%3DPHOTO&imagekey=!1e10!2sAF1QipMMtks6hesbdZgxEHxC8FN9YS-vwugERbfu-3zz&sa=X&ved=0ahUKEwiHl_n89ObXAhXC5oMKHbmkB3wQoioImgEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Fr 11:00-19:00', 'Sa 10:00-18:00', 'Su 11:00-18:00'],
		   },
		   'key': 'chicago',
		   'deliveryCenter': 'chicago',
		},
	 },
	'costa-mesa': {
		'location': {
		   'title': {
			  'suffix': ' - SOCO',
			  'text': 'Costa Mesa',
		   },
		   'breadcrumbID': 101,
		   'imageName': 'costa_mesa_ext_0821',
		   'curalateLabel': 'costa_mesa_south_coast_collection',
		   'hashTag': 'roomandboardSOCO',
		   'jrni': 'SOCO',
		   'hours': {
			  	'range': [
				 	{
						'days': 'Monday – Saturday',
						'times': '10 a.m. – 6 p.m.',
				 	},
				 	{
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 	},
			  	],
		   	},
			'about': {
			   'gallery': {
					'images': [
						{
							'altText': 'Detail of living room with leather sofa at Room &amp; Board Costa Mesa',
							'caption': 'Room & Board Costa Mesa - SOCO is filled with furniture inspired by enduring movements of the past. You’ll find influences from Scandinavian, Shaker, Mid-century, Arts & Crafts and Japanese-inspired design.',
							'fileName': 'costa_mesa_int_6_g',
						  },
						  {
							'altText': 'Detail of bedroom at Room &amp; Board Costa Mesa',
							'caption': 'From filling an entire room to selecting just a few decor items, our room settings show you all the modern design possibilities.',
							'fileName': 'costa_mesa_int_1_g',
						  },
						  {
							'altText': 'Detail of living room with sectional at Room &amp; Board Costa Mesa',
							'caption': 'Our Design Associates are experts at infusing California and West Coast style into homes.',
							'fileName': 'costa_mesa_int_2_g',
						  },
						  {
							'altText': 'Detail of rug at Room &amp; Board Costa Mesa',
							'caption': 'You’ll find a mix of tonal textures, geometric fabrics, vintage-style patterns and more throughout our collection of modern furniture and home decor.',
							'fileName': 'costa_mesa_int_3_g',
						  },
						  {
							'altText': 'Detail of wood table at Room &amp; Board Costa Mesa',
							'caption': 'Room & Board uses sustainable materials whenever possible. Working with natural materials allows our furniture to work in any home, from modern to Mid-century to traditional.',
							'fileName': 'costa_mesa_int_7_g',
						  },
						  {
							'altText': 'Detail of living room area at Room &amp; Board Costa Mesa',
							'caption': 'We love blending our modern furniture with California homes. You’ll see a nod to mid-century design and other classic styles.',
							'fileName': 'costa_mesa_int_8_g',
						  },
						  {
							'altText': 'Detail of outdoor living space at Room &amp; Board Costa Mesa',
							'caption': 'Modern outdoor furniture is a must in California. Check out our weather-proof designs at our Costa Mesa showroom.',
							'fileName': 'costa_mesa_int_4_g',
						  },
						  {
							'altText': 'Detail of home decor area at Room &amp; Board Costa Mesa',
							'caption': 'It’s easy to complete your home with our helpful decor departments. Modern, artisan-crafted throw pillows, rugs, decor and organizational items will help tie your home together.',
							'fileName': 'costa_mesa_int_9_g',
						  },
						  {
							'altText': 'Detail of pillows and fabric selection at Room &amp; Board Costa Mesa',
							'caption': 'Stop in the store or request an appointment online today for free design services at Room & Board Costa Mesa - SOCO. No matter your budget or style, our Design Associates are experts at helping your home feel personal and inviting.',
							'fileName': 'costa_mesa_int_5_g',
						  },
					],
				},
		 	},
		   	'contact': {
			  'phone': '800.952.8566',
			  'email': 'southcoast@roomandboard.com',
		   	},
		   	'address': {
			  'title': 'Room & Board Costa Mesa',
			  'subTitle': 'SOCO',
			  'streetAddress': '3309 Hyland Avenue #A',
			  'city': 'Costa Mesa',
			  'state': 'CA',
			  'zipCode': '92626',
			  'mapLink': 'https://www.google.com/maps?cid=16669869454640426791',
			  'mapImageName': 'costa_mesa_map_0821',
			  'parkingText': 'SOCO has free, dedicated Room & Board parking and general on-site parking, as well as complimentary valet on Saturdays from 9 a.m. to 3:30 p.m.',
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x80dcdf31b5c72319%3A0xe75745f2bffa2f27!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D104169417256578747734%26id%3D6373932592572705634%26target%3DPHOTO&imagekey=!1e10!2sAF1QipPM40wDYEQCS_dYYYrBXHJttAY3hsYV-kQraW0c&sa=X&ved=0ahUKEwjV_fqW2uTXAhVK9YMKHRZ4CM4QoioIjgEwCg&activetab=panorama',
		   },
		   'schema': {
				'openingHours': ['Mo-Sa 10:00-18:00', 'Su 11:00-18:00'],
		   },
		   'key': 'costa-mesa',
		   'deliveryCenter': 'losAngelesArea',
		},
	 },
	 'dallas': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Dallas',
		   },
		   'breadcrumbID': 901,
		   'imageName': 'dallas_ext_0821',
		   'curalateLabel': 'dallas',
		   'hashTag': 'roomandboardDallas',
		   'jrni': 'Dallas',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Saturday',
						'times': '10 a.m. – 6 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '888.985.4224',
			  'email': 'dallas@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Dallas',
			  'subTitle': 'Knox Street District',
			  'streetAddress': '4524 McKinney Avenue',
			  'city': 'Dallas',
			  'state': 'TX',
			  'zipCode': '75205',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@32.8217218,-96.7899963,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x2ee73dafd4e014cf!8m2!3d32.8217218!4d-96.7878076?hl=en',
			  'mapImageName': 'dallas_map_0821',
			  'parkingText': 'Free on-site parking.',
		   },
		   'about': {
			  'gallery': {
				 'images': [
						{
					   'altText': 'Detail of store front with outdoor furniture at Room & Board Dallas',
					   'caption': 'A display of our modern outdoor furniture welcomes you to Room & Board Dallas. You\'ll find free parking at the back of the store.',
					   'fileName': 'dallas_1',
						},
						{
					   'altText': 'Detail of lounge chairs and curved sectional at Room & Board Dallas',
					   'caption': 'Located in the Knox-Henderson District five miles north of downtown Dallas, Room & Board is a destination for modern furniture, quality home decor and gifts.',
					   'fileName': 'dallas_2',
						},
						{
					   'altText': 'Detail of leather sofa and daybed at Room & Board Dallas',
					   'caption': 'Room & Board Dallas is a great place to find inspiration for your home. Our look is both timeless and modern, easily blending with the architectural styles of Texas homes.',
					   'fileName': 'dallas_3',
						},
						{
					   'altText': 'Detail of bedroom with wood furniture at Room & Board Dallas',
					   'caption': 'We believe in using natural materials and colors found in nature for a cohesive look. This philosophy makes our furniture easy to mix and match to create a look you love.',
					   'fileName': 'dallas_4',
						},
						{
					   'altText': 'Detail of small living room area at Room & Board Dallas',
					   'caption': 'More than 90% of our collection is made in the U.S. from top-quality domestic and imported materials. Our relationships with U.S. makers allow us to bring you the best craftsmanship and fastest delivery with the least environmental impact.',
					   'fileName': 'dallas_5',
						},
						{
					   'altText': 'Detail of small round dining table with chairs at Room & Board Dallas',
					   'caption': 'Sustainability is a cornerstone of our business. We source materials responsibly and build furniture to last.',
					   'fileName': 'dallas_6',
						},
						{
					   'altText': 'Detail of upholstered bed in bedroom at Room & Board Dallas',
					   'caption': 'We’re experts at making a house feel like a home. Use our free design services for any size project. Whether you\'re just getting started or working on finishing touches, we\'d love to help.',
					   'fileName': 'dallas_7',
						},
						{
					   'altText': 'Detail of small living room setting at Room & Board Dallas',
					   'caption': 'We take inspiration from enduring design movements to create our timeless modern furniture. You\'ll see nods to Mid-century Modern, Shaker and Asian design throughout our store.',
					   'fileName': 'dallas_8',
						},
						{
					   'altText': 'Detail of small seating area and home decor options at Room & Board Dallas',
					   'caption': 'Stop by our home decor department to shop for bedding, lighting, rugs and pillows. You\'ll find meaningful gifts here, too. We\'re always refreshing this inspiring space to offer you new ideas for your home.',
					   'fileName': 'dallas_9',
						},
						{
					   'altText': 'Detail of home decor selection area at Room & Board Dallas',
					   'caption': 'Visit our Design Center to see all of our fabrics and leathers, get free material samples or work through a design dilemma with our knowledgeable Design Associates.',
					   'fileName': 'dallas_10',
						},
						{
					   'altText': 'Detail of modern dining room area at Room & Board Dallas',
					   'caption': 'Room & Board Dallas is filled with beautiful modern room settings to help you envision how our furniture will look in your home. You\'ll also find displays designed to help you quickly compare items and understand all the options.',
					   'fileName': 'dallas_11',
						},
						{
					   'altText': 'Detail of kids’ bunk bed in bedroom at Room & Board Dallas',
					   'caption': 'Our kids\' furniture is made to the same high standard as all of our designs, using safe materials. From cribs to big-kid beds, it all blends seamlessly with modern style.',
					   'fileName': 'dallas_12',
						},
						{
					   'altText': 'Detail of bedroom adjacent to pillow selection area at Room & Board Dallas',
					   'caption': 'We love adding a pop of color to a room. Use our home decor displays to play with different combinations of pillows, throws and bedding until you find your favorites.',
					   'fileName': 'dallas_13',
						},
				 ],
			  },
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/place/Room+%26+Board/@32.8214291,-96.7876927,3a,75y,260h,100t/data=!3m8!1e1!3m6!1sAF1QipMl8RqIsvA1FJpx8gIynEoCKDVl9f16R-npa6R2!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMl8RqIsvA1FJpx8gIynEoCKDVl9f16R-npa6R2%3Dw224-h298-k-no-pi-10-ya253.13-ro0-fo100!7i9000!8i4500!4m7!3m6!1s0x0:0x2ee73dafd4e014cf!8m2!3d32.8216803!4d-96.7875151!14m1!1BCgIgARICCAI',
		   },
		   'schema': {
			  'openingHours': ['Mo-Sa 10:00-18:00', 'Su 11:00-18:00'],
		   },
		   'key': 'dallas',
		   'deliveryCenter': 'dallas',
		   'pickupId': '1651',
		},
	 },
	'denver': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Denver',
		   },
		   'breadcrumbID': 201,
		   'imageName': 'denver_ext_0821',
		   'curalateLabel': 'denver',
		   'hashTag': 'roomandboardDenver',
		   'jrni': 'Denver',
		   'hours': {
			  	'range': [
					{
						'days': 'Monday – Saturday',
						'times': '10 a.m. – 6 p.m.',
				 	},
				 	{
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 	},
			  	],
		   	},
			'about': {
			   'gallery': {
					'images': [
						{
							'altText': 'Detail of living room with leather sofa at Room &amp; Board Denver',
							'caption': 'Room & Board Denver is conveniently located near other modern furniture stores in the Denver area. Our store uniquely shows off room settings for every room in your home.',
							'fileName': 'denver_int_1_g',
						  },
						  {
							'altText': 'Detail of large living room with daybed at Room &amp; Board Denver',
							'caption': 'Our Design Associates can help with design projects large or small. They are professionals at helping you select furniture and home decor that complement each other.',
							'fileName': 'denver_int_2_g',
						  },
						  {
							'altText': 'Detail of open living and dining area at Room &amp; Board Denver',
							'caption': 'Our Denver showroom artfully combines the best of our modern designs with architecture of Denver homes. The products you’ll find at our store are arranged and mixed in a way that pays homage to the Colorado lifestyle so you can really see how each piece would work in your home.',
							'fileName': 'denver_int_3_g',
						  },
						  {
							'altText': 'Detail of bed next to home decor table at Room &amp; Board Denver',
							'caption': 'You can find furniture for your entire home or just a few finishing touches from our Complete Your Home department. Filled with throw pillows, blankets, lighting, bedding and decorative items, each piece comes with a story you’d be proud to share.',
							'fileName': 'denver_int_4_g',
						  },
						  {
							'altText': 'Detail of living room next to pillow selection at Room &amp; Board Denver',
							'caption': 'Get furniture and home decor suggestions, or paint, color and material recommendations from our Design Associates for free. We’ll also work with you to decide the best layout for your furniture.',
							'fileName': 'denver_int_5_g',
						  },
						  {
							'altText': 'Detail of rooftop lounge and pond area at Room &amp; Board Denver',
							'caption': 'Our recently renovated rooftop is the perfect canvas for Room & Board’s modern outdoor furniture. And don’t forget to sign up for store-hosted events! The rooftop deck is often a hot spot for our Denver events.',
							'fileName': 'denver_int_6_g',
						  },
					],
				},
		 	},
		   	'contact': {
			  'phone': '888.634.5950',
			  'email': 'denver@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Denver',
			  'subTitle': 'Cherry Creek North',
			  'streetAddress': '222 Detroit Street',
			  'city': 'Denver',
			  'state': 'CO',
			  'zipCode': '80206',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@39.7199191,-104.9563997,17z/data=!3m1!4b1!4m5!3m4!1s0x876c7e96580c8d2d:0x4c07a16838a91412!8m2!3d39.719915!4d-104.954211',
			  'mapImageName': 'denver_map_0821',
			  'parkingText': 'Enjoy free on-site parking during store hours. Ask a Design Associate for a token required to exit the lot.',
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x876c7e96580c8d2d%3A0x4c07a16838a91412!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D102374395712837556415%26id%3D6291985225978236562%26target%3DPHOTO&imagekey=!1e10!2sAF1QipM6POqoDT3Zhry_32jKZs3v9IajD_L2IWcizlPx&sa=X&ved=0ahUKEwjP_LLE8ebXAhVL2oMKHWJSDYUQoioIigEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Sa 10:00-18:00', 'Su 11:00-18:00'],
		   },
		   'key': 'denver',
		   'deliveryCenter': 'denver',
		},
	 },
	 'east-hampton': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'East Hampton',
		   },
		   'breadcrumbID': 702,
		   'imageName': 'hampton_ext_0821',
		   'curalateLabel': 'hamptons',
		   'hashTag': 'roomandboardHamptons',
		   'jrni': 'East Hampton',
		   'hours': {
			  'range': [
					{
						'days': 'Monday',
						'times': '10 a.m. – 5 p.m.',
					},
					{
						'days': 'Tuesday – Wednesday',
						'times': 'Closed',
			 		},
					{
						'days': 'Thursday',
						'times': '10 a.m. – 5 p.m.',
			 		},
					{
						'days': 'Friday – Saturday',
						'times': '10 a.m. – 6 p.m.',
					},
					{
						'days': 'Sunday',
						'times': '10 a.m. – 5 p.m.',
					},
			  	],
			},
		   'contact': {
			  'phone': '866.395.7277',
			  'email': 'easthampton@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board East Hampton',
			  'subTitle': 'The Hamptons',
			  'streetAddress': '51 Newtown Lane',
			  'city': 'East Hampton',
			  'state': 'NY',
			  'zipCode': '11937',
			  'mapLink': 'https://goo.gl/maps/eFsYy8WQuBE2',
			  'mapImageName': 'hampton_map_0821',
			  'parkingText': 'Free parking at our on-site parking lot, located behind the store.',
		   },
		   'about': {
			  'gallery': {
				 'images': [
						{
							'altText': 'Side exterior view of Room & Board Hamptons store',
							'caption': 'Shop local and enjoy our $99 white-glove Hamptons delivery. The store is filled with furniture and decor for any season.',
							'fileName': 'Hamptons_interior_19e_6459_g',
					  },
					  {
							'altText': 'Interior view of Room & Board Hamptons store',
							'caption': 'Explore inspiration for every room of your home. You’ll find furniture for living rooms, bedrooms, dining rooms and more.',
							'fileName': 'Hamptons_interior_19e_6466_g',
					  },
					  {
							'altText': 'U-shaped sectional on display at Hamptons showroom',
							'caption': 'Add chic, coastal style to your space with furniture specially curated for this summer destination.',
							'fileName': 'Hamptons_interior_19e_6490_g',
					  },
					  {
							'altText': 'Wide interior view of furniture and decor at Room & Board Hamptons',
							'caption': 'From sprawling sectionals to extravagant dining room furniture to perfect items for hosting guests, you’ll love exploring.',
							'fileName': 'Hamptons_interior_19e_6492_g',
					  },
					  {
							'altText': 'Living room display at Room & Board Hamptons furniture store',
							'caption': 'Find the perfect mix of color, pattern and texture for your home. Room & Board is selective about each material we introduce so it’s not only beautiful, but also will keep up with your modern lifestyle.',
							'fileName': 'Hamptons_interior_19e_6595_g',
					  },
					  {
							'altText': 'Front interior view of Room & Board Hamptons pop-up',
							'caption': 'Enjoy free design services when you visit the store. Our Design Associates are experts at helping make your space come together.',
							'fileName': 'Hamptons_interior_19e_6659_g',
					  },
					  {
							'altText': 'Colorful living room furniture at Room & Board Hamptons',
							'caption': 'A mix of classically inspired styles like mid-century, Shaker, Arts & Crafts and more can be found throughout Room & Board Hamptons. We’ve put our own modern twist on each piece.',
							'fileName': 'Hamptons_interior_19e_6719_g',
					  },
					  {
							'altText': 'Colorful throw pillows displayed on the wall at Room & Board Hamptons',
							'caption': 'We specially craft our furniture in a neutral palette and nature-inspired colors to create a cohesive feel.',
							'fileName': 'Hamptons_interior_19e_6722_g',
					  },
					  {
							'altText': 'Display of small decor items, ready for pick-up, at Room & Board Hamptons',
							'caption': 'Need some last-minute items? Stop in to grab extra sheets, towels and toiletries for guests.',
							'fileName': 'Hamptons_interior_19e_6793_g',
					  },
					  {
							'altText': 'Green and blue outdoor pillows available at Room & Board',
							'caption': 'Wow your Hamptons guests with fun colors for your outdoor space. All of our outdoor furniture is made to withstand sun or rain so all you have to worry about is enjoying it.',
							'fileName': 'Hamptons_interior_19e_6949_g',
					  },
					  {
							'altText': 'Complete your home department with pillows, baskets and blankets at Room & Board',
							'caption': 'Stock up on home decor and easily refresh your space, no matter the season. Throw pillows, blankets, baskets and more are always on-hand at Room & Board East Hampton.',
							'fileName': 'Hamptons_interior_19e_6983_g',
					  },
					  {
							'altText': 'Outdoor sofa with red and orange pillows',
							'caption': 'Bring indoor style outdoors with our expertly crafted outdoor furniture, made to weather any climate.',
							'fileName': 'Hamptons_interior_19e_6464_g',
					  },
				 ],
			  },
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x89e8bbb9711e7a33%3A0x9a597984c85d3e1d!3m1!7e115!4s%2Fmaps%2Fplace%2Froom%2Band%2Bboard%2Bhamptons%2F%4040.9641477%2C-72.1873511%2C3a%2C75y%2C119.58h%2C90t%2Fdata%3D*213m4*211e1*213m2*211s0Xhd4I7v60kd3BncAuHWgw*212e0*214m2*213m1*211s0x89e8bbb9711e7a33%3A0x9a597984c85d3e1d%3Fsa%3DX!5sroom%20and%20board%20hamptons%20-%20Google%20Search!15sCgIgARICCAI&imagekey=!1e10!2sAF1QipMonrEBJXqCEljWKNwdk0z-G00xpGKStICptLng&sa=X&ved=2ahUKEwiVuNKOgJbqAhXzdc0KHfuXCe8Qpx8wCnoECA8QCw',
		   },
		   'schema': {
			  'openingHours': ['Th-Sa 10:00-18:00', 'Su 10:00-17:00'],
		   },
		   'key': 'east-hampton',
		   'deliveryCenter': 'newYorkArea',
		   'pickupId': '1630',
		},
	 },
	'edina': {
		'location': {
			'title': {
				'suffix': '',
				'text': 'Edina',
			},
			'breadcrumbID': 601,
			'imageName': 'edina_ext_0821',
			'curalateLabel': 'edina',
			'hashTag': 'roomandboardEdina',
			'jrni': 'Edina',
			'hours': {
				'range': [
					{
						'days': 'Monday – Saturday',
						'times': '10 a.m. – 6 p.m.',
					},
					{
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
					},
				],
			},
			'about': {
				'gallery': {
				   'images': [
						{
							'altText': 'Detail of staircase and home decor selection at Room &amp; Board Edina',
							'caption': 'One of the mainstays of the Twin Cities’ furniture hub, Room & Board Edina is one of our largest stores in the country. You’ll find modern furniture and home decor for every room of your home.',
							'fileName': 'edina_int_14e',
					  },
					  {
							'altText': 'Detail of bedroom with modern frame wall at Room &amp; Board Edina',
							'caption': 'The high-quality materials and construction techniques we use in all of our products mean they’re made to last and you can buy furniture you feel good about.',
							'fileName': 'edina_interior_10e1_g',
					  },
					  {
							'altText': 'Detail of pillow wall selection at Room &amp; Board Edina',
							'caption': 'Complete your home with furniture or just with a few finishing touches. Our Complete Your Home Department is filled with beautiful colors, textures and patterns that add an inviting touch to your home. Explore our throw pillows, rugs, blankets and more.',
							'fileName': 'edina_int_14e11',
					  },
					  {
							'altText': 'Detail of large, intricate wool rug in living room at Room &amp; Board Edina',
							'caption': 'Room & Board products are inspired by colors and patterns from nature, not from fashion, so they never go out of style. See how all of them work together in living rooms, bedrooms, dining rooms and more.',
							'fileName': 'edina_int_14e7',
					  },
					  {
							'altText': 'Detail of two leather chairs next to home decor options at Room &amp; Board Edina',
							'caption': 'More than 90 percent of Room & Board furniture is made in America of high-quality U.S. and imported materials. We love how natural materials bring warmth into the home. Our modern designs always highlight these natural materials and are made in a sustainable way.',
							'fileName': 'edina_int_14e4',
					  },
					  {
							'altText': 'Detail of pillow wall selection with frames and vases at Room &amp; Board Edina',
							'caption': 'Our easy-to-shop Edina store includes room settings, as well as displays of layered items like bedding, pillows and blankets. Learn how to make your best bed or find the perfect throw by chatting with an Edina Design Associate or browsing the France Avenue store.',
							'fileName': 'edina_int_14e3',
					  },
					  {
							'altText': 'Detail of shelves of home decor options at Room &amp; Board Edina',
							'caption': 'Beautiful color stories will inspire you to find the best furniture and home decor for your space. We love adding pops of color to a neutral room.',
							'fileName': 'edina_int_14e8',
					  },
					  {
							'altText': 'Detail of bedding options at Room &amp; Board Edina',
							'caption': 'Stop by Room & Board Edina today for free design services. Our Design Associates can work with any space, budget and needs to help create a personalized, inviting Midwest home.',
							'fileName': 'edina_int_14e2',
					  },
					],
				},
			},
			'contact': {
				'phone': '866.401.7785',
				'email': 'edina@roomandboard.com',
			},
			'address': {
				'title': 'Room & Board Edina',
				'subTitle': 'Across from Galleria Edina',
				'streetAddress': '7010 France Avenue South',
				'city': 'Edina',
				'state': 'MN',
				'zipCode': '55435',
				'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@44.8755751,-93.3317247,17z/data=!3m1!4b1!4m5!3m4!1s0x87f62402d10d9dc5:0x9f40b41cc185ecea!8m2!3d44.8755713!4d-93.329536',
				'mapImageName': 'edina_map_0821',
				'parkingText': 'Free on-site parking.',
			},
			'virtualTourLink': {
				'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x87f62402d10d9dc5%3A0x9f40b41cc185ecea!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D112613824647218622825%26id%3D6460411412903713650%26target%3DPHOTO&imagekey=!1e10!2sAF1QipMseU1zkBnORb3FGNxeefnPrA7W2jCKZTB45iol&sa=X&ved=0ahUKEwjVkr299ebXAhUs5IMKHcTUCakQoioIjwEwCg&activetab=panorama',
			},
			'schema': {
				'openingHours': ['Mo-Sa 10:00-18:00', 'Su 11:00-18:00'],
			},
			'key': 'edina',
			'deliveryCenter': 'mplsStp',
		},
	},
	'los-angeles': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Los Angeles',
		   },
		   'breadcrumbID': 102,
		   'imageName': 'los_angeles_ext_0821',
		   'curalateLabel': 'los_angeles',
		   'hashTag': 'roomandboardLA',
		   'jrni': 'Los Angeles',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Saturday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'about': {
				'gallery': {
			   		'images': [
						{
							'altText': 'Detail of dining table with chairs at Room &amp; Board Los Angeles',
							'caption': 'Step inside Room & Board Los Angeles to find modern home design inspiration. Our light-filled showroom shows the best of the best when it comes to modern, mid-century room settings with a coastal twist.',
							'fileName': 'los_angeles_int_7_g',
				  		},
				  		{
							'altText': 'Detail of living room and dining room at Room &amp; Board Los Angeles',
							'caption': 'You’ll love finding a mix of dining rooms, living rooms, bedrooms and more, all made for the way you live.',
							'fileName': 'los_angeles_int_1_g',
				  		},
				  		{
							'altText': 'Detail of wood table at Room &amp; Board Los Angeles',
							'caption': 'Room & Board furniture is sustainably and domestically made whenever possible. Working with natural materials in our furniture and home decor gives each piece a great story, as well as lasting construction for your home.',
							'fileName': 'los_angeles_int_3_g',
				  		},
				  		{
							'altText': 'Detail of sectional at Room &amp; Board Los Angeles',
							'caption': 'From sprawling sectionals to finding the perfect vase, our Design Associates can help you create a home you love.',
							'fileName': 'los_angeles_int_4_g',
				  		},
				  		{
							'altText': 'Detail of wall of frame selection at Room &amp; Board Los Angeles',
							'caption': 'You know that frame wall you’ve been dreaming about? Find all the inspiration you need at Room & Board Los Angeles. We can help you with decor for your entire home and offer free design services to make your home feel inviting and personal.',
							'fileName': 'los_angeles_int_2_g',
				  		},
				  		{
							'altText': 'Detail of daybed at Room &amp; Board Los Angeles',
							'caption': 'No matter your style, Room & Board has modern furniture and home decor for you. Our designs draw on classic inspiration from the past and are updated for today’s contemporary lifestyle.',
							'fileName': 'los_angeles_int_5_g',
				  		},
				  		{
							'altText': 'Detail of pillow and rug selection at Room &amp; Board Los Angeles',
							'caption': 'Come see for yourself and explore all Room & Board Los Angeles has to offer. Room settings, design centers, samples and more are all waiting for you!',
							'fileName': 'los_angeles_int_8',
				  		},
			   		],
				},
		 },
		   'contact': {
			  'phone': '866.388.0627',
			  'email': 'losangeles@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Los Angeles',
			  'subTitle': 'Helms Bakery Building',
			  'streetAddress': '3231 Helms Avenue',
			  'city': 'Los Angeles',
			  'state': 'CA',
			  'zipCode': '90034',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@34.0296669,-118.3868587,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2b983a852aacb:0x8b589df9e5c26e24!8m2!3d34.03049!4d-118.384583',
			  'mapImageName': 'los_angeles_map_0821',
			  'parkingText': 'Free on-site parking.',
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x80c2b983a852aacb%3A0x8b589df9e5c26e24!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D113841530605414927243%26id%3D6117603212558807714%26target%3DPHOTO&imagekey=!1e10!2sAF1QipM8sgQ1K_EOwziBv6TTJCWmk0VTjRHcgz7W569E&sa=X&ved=0ahUKEwjp2LGzk9DXAhVH6oMKHVDhBsEQoioIgwEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Sa 11:00-19:00', 'Su 11:00-18:00'],
		   },
		   'key': 'los-angeles',
		   'deliveryCenter': 'losAngelesArea',
		},
	 },
	'oak-brook': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Oak Brook Store & Outlet',
		   },
		   'breadcrumbID': 402,
		   'titleOverride': 'Oak Brook',
		   'imageName': 'oak_brook_ext_0821',
		   'curalateLabel': 'oak_brook',
		   'hashTag': 'roomandboardOakBrook',
		   'jrni': 'Oak Brook',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Friday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Saturday',
						'times': '10 a.m. – 6 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '888.985.4157',
			  'email': 'oakbrook@roomandboard.com',
			  'emailLinkOverride': 'Oak Brook store',
			  'outletConfirm': 'Outlet items change daily and must be purchased in person.',
		   },
		   'address': {
			  'title': 'Room & Board Oak Brook',
			  'subTitle': 'Located near Oakbrook Center',
			  'streetAddress': '2525 West 22nd Street',
			  'city': 'Oak Brook',
			  'state': 'IL',
			  'zipCode': '60523',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@41.8449954,-87.981754,17z/data=!3m1!4b1!4m5!3m4!1s0x880e4db6f74eb8eb:0xcbc67d3696f9d64b!8m2!3d41.8449914!4d-87.9795653',
			  'mapImageName': 'oak_brook_map_0821',
			  'parkingText': 'Free on-site parking.',
		   },
		   'about': {
			  'gallery': {
				 'images': [
						{
					   'altText': 'Detail of wood dining table and chairs at Room & Board Oak Brook',
					   'caption': 'Find just the right modern furniture and home decor for your space at Room & Board Oak Brook. We display full room settings throughout our showroom so you can get a taste for what our products look like all together.',
					   'fileName': 'oak_brook_int_1',
						},
						{
					   'altText': 'Detail of white sofa and chair in small living room at Room & Board Oak Brook',
					   'caption': 'Our Design Associates are experts at pairing our furniture together, but they\'re also masters at helping you select items that mix and match with pieces you already have in your home. This expert design advice is always available to you for free. Request an appointment online or simply stop into Room & Board Oak Brook for product and material suggestions, paint color recommendations and room layout ideas.',
					   'fileName': 'oak_brook_int_2',
						},
						{
					   'altText': 'Detail of wood furniture in dining room at Room & Board Oak Brook',
					   'caption': 'We purposefully craft our furniture and home decor from natural materials so not only is each piece made to last, but it\'s made with a story you can be proud to share. And because each piece is inspired by nature, they naturally find a place in your home that complements other items.',
					   'fileName': 'oak_brook_int_4',
						},
						{
					   'altText': 'Detail of velvet armchair and wood side table at Room & Board Oak Brook',
					   'caption': 'What kind of furniture design speaks to you? We take a cue from classic furniture styles like Mid-century, Shaker, Arts & Crafts, Japanese and Danish and then infuse a modern point of view so our furniture and accessories transition beautifully into any home.',
					   'fileName': 'oak_brook_int_5',
						},
						{
					   'altText': 'Detail of open floor plan with variety of areas at Room & Board Oak Brook',
					   'caption': 'You’ll love seeing how we’ve paired our modern furniture with the welcoming architecture, colors and styles of the Midwest. Clean lines, modern shapes, natural materials and warm, inviting woods all help tell the story of a personal, inviting home.',
					   'fileName': 'oak_brook_int_6',
						},
						{
					   'altText': 'Detail of Kids\' department at Room & Board Oak Brook',
					   'caption': 'Where’s the best place to find modern kids’ furniture? Room & Board Oak Brook! Our kids’ department shows the most creative uses for our kids’ furniture, and each piece is made to the same high standard as the rest of our furniture. We believe in making kids’ furniture right so you only have to buy it once.',
					   'fileName': 'oak_brook_int_3',
						},
				 ],
			  },
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x880e4db6f74eb8eb%3A0xcbc67d3696f9d64b!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D103589742062781648225%26id%3D6392319860397948674%26target%3DPHOTO&imagekey=!1e10!2sAF1QipNcSqNEKpt0-YzlgrHgqcgDR4kfBhiUSu35KkKp&sa=X&ved=0ahUKEwijv5qY9ebXAhXn6oMKHdiQAqgQoioIigEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Fr 11:00-19:00', 'Sa 10:00-18:00', 'Su 11:00-18:00'],
		   },
		   'key': 'oak-brook',
		   'deliveryCenter': 'chicago',
		   'pickupId': '1605',
		},
	},
	'outlet': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Weekend Outlet – Golden Valley',
		   },
		   'breadcrumbID': 602,
		   'imageName': 'outlet_ext_0821',
		   'hours': {
			  'range': [
				 {
						'days': 'Saturday',
						'times': '10 a.m. – 4 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '12 p.m. – 4 p.m.',
				 },
				 {
						'days': 'Monday – Friday',
						'times': 'Closed',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '',
			  'email': '',
		   },
		   'address': {
			  'title': 'Room & Board Weekend Outlet – Golden Valley',
			  'subTitle': '',
			  'streetAddress': '4600 Olson Memorial Highway',
			  'city': 'Golden Valley',
			  'state': 'MN',
			  'zipCode': '55422',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board+Outlet/@44.985977,-93.3397311,17z/data=!4m5!3m4!1s0x52b3337eb84f5a2b:0xc21f626a91bf684f!8m2!3d44.9859732!4d-93.3375424',
			  'mapImageName': 'outlet_map_0821',
			  'parkingText': 'Free on-site parking.',
		   },
		   'about': {
			  'gallery': {
				 'images': [
						{
					   'altText': 'Detail of entrance area at Room & Board Golden Valley Outlet',
					   'caption': 'Visit the Room & Board Outlet in Golden Valley. The outlet is open only on Saturdays and Sundays. You’ll find overstock furniture and home decor, returned items and slightly imperfect pieces - all at a reduced price.',
					   'fileName': 'outlet_int_1',
						},
						{
					   'altText': 'Detail of rug selection at Room & Board Golden Valley Outlet',
					   'caption': 'We offer reduced prices on Room & Board rugs, lighting, frames, artwork and furniture.',
					   'fileName': 'outlet_int_2',
						},
						{
					   'altText': 'Detail of sofa options at Room & Board Golden Valley Outlet',
					   'caption': 'This modern furniture outlet has merchandise that changes every weekend, so you\'re always sure to find something fresh.',
					   'fileName': 'outlet_int_3',
						},
						{
					   'altText': 'Detail of lounge chair options at Room & Board Golden Valley Outlet',
					   'caption': 'Not only is the Room & Board Outlet stocked with product, but there are also helpful staff members to help you find what you need or answer product questions.',
					   'fileName': 'outlet_int_4',
						},
						{
					   'altText': 'Detail of dining tables and chairs at Room & Board Golden Valley Outlet',
					   'caption': 'The outlet is organized by room, so you can easily find the type of furniture you’re looking for.',
					   'fileName': 'outlet_int_5',
						},
						{
					   'altText': 'Detail of cash register area at Room & Board Golden Valley Outlet',
					   'caption': 'Stop in next weekend to shop reduced Room & Board items, available for both pick-up and delivery.',
					   'fileName': 'outlet_int_6',
						},
				 ],
			  },
		   },
		   'virtualTourLink': '',
		   'schema': {
			  'openingHours': ['Sa 10:00-16:00', 'Su 12:00-16:00'],
		   },
		   'key': 'outlet',
		},
	 },
	'palm-springs': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Palm Springs',
		   },
		   'breadcrumbID': 103,
		   'imageName': 'palm_springs_ext_0821',
		   'curalateLabel': 'palm_springs',
		   'hashTag': 'roomandboardPalmSprings',
		   'jrni': 'Palm Springs',
		   'metaDescription': 'Discover modern furniture and home decor at our Palm Springs pop-up store!',
		   'hours': {
			  'range': [
				 {
						'days': 'Thursday – Saturday',
						'times': '10 a.m. – 6 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 5 p.m.',
				 },
				 {
						'days': 'Monday',
						'times': 'By appointment only',
				 },
				 {
						'days': 'Tuesday – Wednesday',
						'times': 'Closed',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '855.246.0973',
			  'email': 'palmsprings@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Palm Springs',
			  'subTitle': 'Uptown Design District',
			  'streetAddress': '830 North Palm Canyon Drive',
			  'city': 'Palm Springs',
			  'state': 'CA',
			  'zipCode': '92262',
			  'mapLink': 'https://www.google.com/maps?cid=17745153404738257477&hl=en',
			  'mapImageName': 'palm_springs_map_0821',
			  'parkingText': 'Free parking at our on-site lot behind the store.',
		   },
		   'virtualTourLink': {
				'cdata': 'https://www.google.com/maps/uv?pb=!1s0x80db1b26d3cfe941%3A0xf6437300f63cc245!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipO9JH97j9otuz7oqJg6_Pfl5rTQX5kCQJ9g3GNg%3Dw480-h320-k-no!5sroom%20%26%20board%20palm%20springs%20-%20Google%20Search!15sCgIgARICCAI&imagekey=!1e10!2sAF1QipNrdbgDTG8r3nRTyfgzWa86Rdbi9rGZuafgheDn&hl=en&sa=X&ved=2ahUKEwiv-o-YzZLyAhVYZc0KHeetDZIQoiowE3oECDcQAw',
		 	},
		   'schema': {
			  'openingHours': ['Thu-Sa 10:00-18:00', 'Su 11:00-17:00'],
		   },
		   'key': 'palm-springs',
		   'deliveryCenter': 'losAngelesArea',
		},
	 },
	 'paramus': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Paramus',
		   },
		   'breadcrumbID': 1201,
		   'imageName': 'paramus_ext_0224',
		   'curalateLabel': 'paramus',
		   'hashTag': 'roomandboardParamus',
		   'jrni': 'Paramus',
		   'metaDescription': 'Shop modern furniture and home decor at Room & Board Paramus, a modern furniture store in the Paramus Design Center.',
		   'hours': {
				'range': [
			   {
					  'days': 'Monday – Saturday',
					  'times': '11 a.m. – 7 p.m.',
			   },
			   {
					  'days': 'Sunday',
					  'times': 'Closed',
			   },
				],
		 },
		 'contact': {
				'phone': '888.985.4568',
				'email': 'Paramus@roomandboard.com',
		 },
		   'address': {
			  'title': 'Room & Board Paramus',
			  'subTitle': 'Paramus Design Center ',
			  'streetAddress': '762 Route 17 N., Suite A',
			  'city': 'Paramus',
			  'state': 'NJ',
			  'zipCode': '07652',
			  'mapLink': 'https://goo.gl/maps/CXQWciihWhz5VMnM8',
			  'mapImageName': 'paramus_map',
			  'directions': 'From NJ-17N, exit at the Paramus Design Center. From NJ-17S, exit at W Ridgewood Avenue and make a U-Turn to enter NJ-17N.  Proceed to the back parking lot and loop around the 762 building to Room & Board.',
			  'parkingText': 'Enjoy free onsite public parking.',
		   },
		   'virtualTourLink': {
				'cdata': 'https://www.google.com/local/place/fid/0x89c2e57c7033c6b1:0x2e652620093a327b/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipO7icQArywzgfqcMtTpJ4nvUFL8sNm8B54ySgok%3Dw160-h106-k-no-pi0.3147635-ya22.07372-ro-0.932967-fo100&ik=CAoSLEFGMVFpcE83aWNRQXJ5d3pnZnFjTXRUcEo0bnZVRkw4c05tOEI1NHlTZ29r',
		 },
		   'schema': {
				'openingHours': ['Mo-Sa 11:00-19:00'],
		 },
		   'key': 'paramus',
		   'deliveryCenter': 'newYorkArea',
		},
	 },
	'pasadena': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Pasadena',
		   },
		   'breadcrumbID': 104,
		   'imageName': 'pasadena_ext_0821',
		   'curalateLabel': 'pasadena',
		   'hashTag': 'roomandboardPasadena',
		   'jrni': 'Pasadena',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Saturday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '855.246.0974',
			  'email': 'pasadena@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Pasadena',
			  'streetAddress': '42 S De Lacey Avenue',
			  'city': 'Pasadena',
			  'state': 'CA',
			  'zipCode': '91105',
			  'mapLink': 'https://www.google.com/maps?cid=6013406235298582059',
			  'mapImageName': 'pasadena_map_0821',
			  'parkingText': 'A parking garage is located across the street from our store and metered street parking is also available.<br/><a href="https://goo.gl/maps/czrmk1UpFRwxjefa6" target="_blank">Get directions to the parking garage</a>',
		   },
		   'virtualTourLink': {
			  'cdata': 'https://goo.gl/maps/fEvK4ettKxUZwXP59',
		   },
		   'schema': {
			  'openingHours': ['Mo-Sa 11:00-19:00', 'Su 11:00-18:00'],
		   },
		   'key': 'pasadena',
		   'deliveryCenter': 'losAngelesArea',
		},
	 },
	'portland': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Portland',
		   },
		   'breadcrumbID': 801,
		   'imageName': 'portland_ext_0821',
		   'curalateLabel': 'portland',
		   'hashTag': 'roomandboardPortland',
		   'jrni': 'Portland',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Friday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Saturday',
						'times': '10 a.m. – 6 p.m.',
			 	},
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '855.246.0972',
			  'email': 'portland@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Portland',
			  'subTitle': 'Pearl District',
			  'streetAddress': '380 Northwest 13th Avenue',
			  'city': 'Portland',
			  'state': 'OR',
			  'zipCode': '97209',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@45.5255693,-122.6861711,17z/data=!3m1!4b1!4m5!3m4!1s0x549509f8a19f8adf:0x3e9fd31272b375c4!8m2!3d45.5255656!4d-122.6839824',
			  'mapImageName': 'portland_map_0821',
			  'parkingText': 'Enjoy two hours of free validated parking at the Brewery Blocks Parking Garage (a.k.a Whole Foods® Parking Garage) located at 122 NW 12th Avenue. Enter on 13th between Couch and Davis Streets. Present your parking ticket to a Design Associate for validation.<br/><a href="https://www.google.com/maps/place/Brewery+Blocks+Parking/@45.5239439,-122.6851216,17z/data=!4m13!1m7!3m6!1s0x54950a0244378033:0xac6ba99518ccc486!2s122+NW+12th+Ave,+Portland,+OR+97209!3b1!8m2!3d45.5239402!4d-122.6829329!3m4!1s0x54950a02448f951d:0x1334cade4b45eb1a!8m2!3d45.5239402!4d-122.6829329" target="_blank">Get directions to Brewery Blocks Parking Garage</a>',
			   'publicTransitText': {
				   'publicTransitOption': [
					   'NS Line or B Loop streetcar: to Northwest 11th and Glisan',
					   '77 bus: to Northwest Glisan and 12th or Northwest Everett and 14th',
				   ],
			 },
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x549509f8a19f8adf%3A0x3e9fd31272b375c4!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D115998034464661734403%26id%3D6426357829783941170%26target%3DPHOTO&imagekey=!1e10!2sAF1QipPRG7BTUvli1uokTDSGILepcwmVpaN3xc-cFht4&sa=X&ved=0ahUKEwiJ3v_m9ebXAhXF7oMKHbkcAdkQoioIiwEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Fr 11:00-19:00', 'Sa 10:00-18:00', 'Su 11:00-18:00'],
		   },
		   'key': 'portland',
		   'deliveryCenter': 'portland',
		},
	 },
	'san-diego': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'San Diego',
		   },
		   'breadcrumbID': 105,
		   'imageName': 'san_diego_ext_0821',
		   'curalateLabel': 'san_diego',
		   'hashTag': 'roomandboardSanDiego',
		   'jrni': 'San Diego',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Saturday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '800.952.8455',
			  'email': 'sandiego@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board San Diego',
			  'subTitle': 'Westfield UTC',
			  'streetAddress': '4525 La Jolla Village Drive',
			  'city': 'San Diego',
			  'state': 'CA',
			  'zipCode': '92122',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@32.8708927,-117.212192,17z/data=!3m1!4b1!4m5!3m4!1s0x80dc072d44ffffff:0x65cef4585c7dd8d6!8m2!3d32.8708882!4d-117.2100033',
			  'mapImageName': 'san_diego_map_0821',
			  'parkingText': 'Enjoy two hours of free parking at Westfield UTC. Present your parking ticket to a Design Associate for an additional two hours of free validated parking.',
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x80dc072d44ffffff%3A0x65cef4585c7dd8d6!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D102330294009146773607%26id%3D6461636506414721618%26target%3DPHOTO&imagekey=!1e10!2sAF1QipOYTwAS9a9rgD1lHtVNF9nN4Z_-0bj4Nj82vu1J&sa=X&ved=0ahUKEwjVzabFk9DXAhWL7oMKHbf9CzQQoioIigEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Sa 11:00-19:00', 'Su 11:00-18:00'],
		   },
		   'key': 'san-diego',
		   'deliveryCenter': 'sanDiego',
		},
	},
	'san-francisco': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'San Francisco',
		   },
		   'breadcrumbID': 106,
		   'imageName': 'san_francisco_ext_0821',
		   'curalateLabel': 'san_francisco',
		   'hashTag': 'roomandboardSF',
		   'jrni': 'San Francisco',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Friday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Saturday',
						'times': '10 a.m. – 6 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '855.246.0975',
			  'email': 'sanfrancisco@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board San Francisco',
			  'subTitle': 'SoMa',
			  'streetAddress': '685 Seventh Street',
			  'city': 'San Francisco',
			  'state': 'CA',
			  'zipCode': '94103',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@37.7722666,-122.4039118,17z/data=!3m1!4b1!4m5!3m4!1s0x808f7e2cb1ee40b7:0xfe3f63bd1d3b71ff!8m2!3d37.7722624!4d-122.4017231',
			  'mapImageName': 'san_francisco_map_0821',
			  'parkingText': 'Free on-site parking.',
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x808f7e2cb1ee40b7%3A0xfe3f63bd1d3b71ff!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D102121525580768068109%26id%3D6461644136557811042%26target%3DPHOTO&imagekey=!1e10!2sAF1QipMvNXgXRskigRNNqWEzeyymOYqr9Q1pRxOLNMFa&sa=X&ved=0ahUKEwidz-7Pk9DXAhWi8YMKHVjpAKoQoioIiAEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Fr 11:00-19:00', 'Sa 10:00-18:00', 'Su 11:00-18:00'],
		   },
		   'key': 'san-francisco',
		   'deliveryCenter': 'sanFrancisco',
		},
	 },
	'seattle': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Seattle',
		   },
		   'breadcrumbID': 1001,
		   'imageName': 'seattle_ext_0821',
		   'curalateLabel': 'seattle',
		   'hashTag': 'roomandboardSeattle',
		   'jrni': 'Seattle',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Saturday',
						'times': '10 a.m. – 8 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '800.952.8344',
			  'email': 'seattle@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Seattle',
			  'subTitle': 'University Village',
			  'streetAddress': '2675 NE University Village Street',
			  'city': 'Seattle',
			  'state': 'WA',
			  'zipCode': '98105',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@47.6627175,-122.3007167,17z/data=!3m2!4b1!5s0x5490148f545ec80f:0x7edf0a3ac59b9ad5!4m5!3m4!1s0x54901488a8709471:0x1a732bec831a9bad!8m2!3d47.6627139!4d-122.298528',
			  'mapImageName': 'seattle_map_0821',
			  'parkingText': 'Free on-site parking.',
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x54901488a8709471%3A0x1a732bec831a9bad!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D103152559399562899534%26id%3D6422064451296579282%26target%3DPHOTO&imagekey=!1e10!2sAF1QipOAATG6yzng8C1VOkjy5JkvJwTOplcY7hHGwrO-&sa=X&ved=0ahUKEwjCy4H_9ebXAhXl3YMKHaMbCq8QoioIkgEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Sa 11:00-19:00'],
		   },
		   'key': 'seattle',
		   'deliveryCenter': 'seattle',
		},
	 },
	 'skokie': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Skokie',
		   },
		   'breadcrumbID': 403,
		   'imageName': 'skokie_ext_0821',
		   'curalateLabel': 'skokie',
		   'hashTag': 'roomandboardSkokie',
		   'jrni': 'Skokie',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Friday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Saturday',
						'times': '10 a.m. – 6 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '888.985.3738',
			  'email': 'skokie@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Skokie',
			  'subTitle': 'Located near Old Orchard Mall',
			  'streetAddress': '10071 Skokie Boulevard',
			  'city': 'Skokie',
			  'state': 'IL',
			  'zipCode': '60077',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@42.0646967,-87.7478536,17z/data=!3m1!4b1!4m5!3m4!1s0x880fc585638e292d:0x4fb25685c747731d!8m2!3d42.0646927!4d-87.7456649',
			  'mapImageName': 'skokie_map_0821',
			  'parkingText': 'Free on-site parking.',
		   },
		   'about': {
			  'gallery': {
				 'images': [
						{
					   'altText': 'Detail of pillow selection wall at Room & Board Skokie',
					   'caption': 'Room & Board Skokie was remodeled in 2019 to feature modern furniture in a fresh, bright setting.',
					   'fileName': 'skokie_int_0.1',
						},
						{
					   'altText': 'Detail of adjacent small living and dining areas at Room & Board Skokie',
					   'caption': 'Explore creative ideas for room and furniture layouts, from small spaces to sprawling square footage.',
					   'fileName': 'skokie_int_0.2',
						},
						{
					   'altText': 'Detail of leather sofa and leather daybed at Room & Board Skokie',
					   'caption': 'With a wide variety of modern furniture and rooms displays, plus friendly Design Associates who can help design your space for free, you’re sure to find solutions for your home.',
					   'fileName': 'skokie_int_0.3',
						},
						{
					   'altText': 'Detail of living room with leather and wood furniture at Room & Board Skokie',
					   'caption': 'Room & Board Skokie is filled with home inspiration for living rooms, dining rooms, bedrooms and more. Our store features entire room settings so ideas are limitless.',
					   'fileName': 'skokie_int_1',
						},
						{
					   'altText': 'Detail of living space next to canopy bed at Room & Board Skokie',
					   'caption': 'Our Design Associates are experts at making your home together, whether you’re shopping for modern furniture to fill an entire room or just looking for the finishing touches.',
					   'fileName': 'skokie_int_3',
						},
						{
					   'altText': 'Detail of Kids\' department at Room & Board Skokie',
					   'caption': 'Our Kids department will keep your little ones busy while you shop. Fun products and creative layouts will keep you inspired, too!',
					   'fileName': 'skokie_int_2',
						},
				 ],
			  },
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x880fc585638e292d%3A0x4fb25685c747731d!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D116041416198376460501%26id%3D6460313173858882946%26target%3DPHOTO&imagekey=!1e10!2sAF1QipOYoEjrRd04LeuMIMQA9R7iD3mjlySKISmt2YV9&sa=X&ved=0ahUKEwi_-_Tq78_XAhVq0YMKHbaRD4gQoioIfzAK&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Fr 11:00-19:00', 'Sa 10:00-18:00', 'Su 11:00-18:00'],
		   },
		   'key': 'skokie',
		   'deliveryCenter': 'chicago',
		   'pickupId': '1605',
		},
	 },
	 'washington-dc': {
		'location': {
		   'title': {
			  'suffix': '',
			  'text': 'Washington, D.C.',
		   },
		   'breadcrumbID': 1101,
		   'imageName': 'washington_ext_0821',
		   'curalateLabel': 'washington_dc',
		   'hashTag': 'roomandboardDC',
		   'jrni': 'Washington DC',
		   'hours': {
			  'range': [
				 {
						'days': 'Monday – Saturday',
						'times': '11 a.m. – 7 p.m.',
				 },
				 {
						'days': 'Sunday',
						'times': '11 a.m. – 6 p.m.',
				 },
			  ],
		   },
		   'contact': {
			  'phone': '877.387.0091',
			  'email': 'washingtondc@roomandboard.com',
		   },
		   'address': {
			  'title': 'Room & Board Washington, D.C.',
			  'subTitle': 'Located at 14th and T Street',
			  'streetAddress': '1840 14th Street, NW',
			  'city': 'Washington, D.C.',
			  'state': '',
			  'zipCode': '20009',
			  'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@38.915355,-77.0345472,17z/data=!3m1!4b1!4m5!3m4!1s0x89b7b7e812894611:0x3d2251694c471b96!8m2!3d38.9153508!4d-77.0323585',
			  'mapImageName': 'washington_map_0821',
			  'parkingText': 'Enjoy two hours of free validated parking at the lot on the corner of 14th Street and Swann Street (the same block the showroom is on). Present your parking ticket to a Design Associate for validation.',
			  'publicTransitText': {
				 'publicTransitOption': [
						'Green or Yellow Metrorail: to U Street/African-American Civil War Memorial/Cardozo',
						'Woodley Park/Adams Morgan to McPherson Square circulator bus: to 14th and U Street, NW',
						'90, 92 or 96 bus: to 14th and U Street, NW',
						'50, 52 or 54 bus: to 14th and T Street, NW',
				 ],
			  },
		   },
		   'about': {
			  'gallery': {
				 'images': [
						{
					   'altText': 'Detail of lounge seating area next to staircase at Room & Board Washington D.C.',
					   'caption': 'Welcome to Room & Board Washington D.C., where floors of modern home inspiration can show you all the possibilities for your own home.',
					   'fileName': 'wdc_int_3',
						},
						{
					   'altText': 'Detail of sofa and coffee table in living room at Room & Board Washington D.C.',
					   'caption': 'Room & Board makes furniture with a focus on sustainable design, natural materials and modern style. See full room settings to find what speaks to you.',
					   'fileName': 'wdc_int_2',
						},
						{
					   'altText': 'Detail of small sleeper sofa by frame gallery at Room & Board Washington D.C.',
					   'caption': 'Furnishing an entire room or just looking for finishing touches? Our Design Associates and shopping departments can help you along the way.',
					   'fileName': 'wdc_int_4',
						},
						{
					   'altText': 'Detail of slipcovered sofa with pillow options at Room & Board Washington D.C.',
					   'caption': 'Well designed furniture and decor departments can help you with exactly what you need, whether you’re looking for rug samples, bedding, frame wall ideas or more.',
					   'fileName': 'wdc_int_6',
						},
						{
					   'altText': 'Detail of patio with outdoor furniture and umbrella at Room & Board Washington D.C.',
					   'caption': 'Check out modern outdoor furniture on our rooftop. (We also regularly host events on the roof, so be sure you get on our invite list!)',
					   'fileName': 'wdc_int_1',
						},
						{
					   'altText': 'Detail of outdoor furniture on gravel outside at Room & Board Washington D.C.',
					   'caption': 'Work with a Design Associate in our Washington D.C. design center. No matter your budget, style or space, they’re experts at selecting furniture, decor, paint and materials. Request an appointment today or simply stop in the Room & Board store.',
					   'fileName': 'wdc_int_5',
						},
						{
					   'altText': 'Detail of fabric selection area at Room & Board Washington D.C.',
					   'caption': 'We’ll see you at the corner of 14th and T Street!',
					   'fileName': 'wdc_int_7',
						},
				 ],
			  },
		   },
		   'virtualTourLink': {
			  'cdata': 'https://www.google.com/maps/uv?hl=en&pb=!1s0x89b7b7e812894611%3A0x3d2251694c471b96!2m20!8m2!1m1!1e2!16m16!1b1!2m2!1m1!1e1!2m2!1m1!1e3!2m2!1m1!1e6!2m2!1m1!1e4!2m2!1m1!1e5!3m1!7e115!4shttps%3A%2F%2Fpicasaweb.google.com%2Flh%2Fsredir%3Funame%3D115132975995670193116%26id%3D6373932571164965650%26target%3DPHOTO&imagekey=!1e10!2sAF1QipNjVUKGnA6jlnsyWPAijesmq8JxdtA6fFMk9OPz&sa=X&ved=0ahUKEwi1itjw9ObXAhUX24MKHcqnA-QQoioImwEwCg&activetab=panorama',
		   },
		   'schema': {
			  'openingHours': ['Mo-Sa 11:00-19:00', 'Su 11:00-18:00'],
		   },
		   'key': 'washington-dc',
		   'deliveryCenter': 'washingtonDc',
		   'pickupId': '1650',
		},
	 },
};

export const deliveryCenterData = {
	atlanta: {
		'title': 'Atlanta Area Delivery Center',
		'hours': [
			{
				'days': 'Monday – Saturday',
				'times': '9 a.m. – 5 p.m.',
			},
			{
				'days': 'Sunday',
				'times': 'Closed',
			},
		],
		'contact': {
			'phone': '678.867.6845',
			'email': 'DeliveryTeamAtlanta@roomandboard.com',
		},
		'address': {
			'title': 'Room & Board Atlanta Area Delivery Center',
			'streetAddress': '215 Hembree Park Drive, Suite 115',
			'city': 'Roswell',
			'state': 'GA',
			'zipCode': '30076',
			'mapLink': 'https://www.google.com/maps/place/Room+%26+Board+-+Atlanta+Area+Delivery+Center/@34.054951,-84.3204952,17z/data=!3m1!4b1!4m5!3m4!1s0x88f574fb37db28b5:0x6d2ed76e3510542d!8m2!3d34.0549466!4d-84.3183065',
		},
		'schema': {
			'openingHours': ['Tu-Sa 09:00-16:00'],
		},
	},
	boston: {
		'title': 'Boston Area Delivery Center',
		'hours': [
		   {
			  'days': 'Monday – Saturday',
			  'times': '9 a.m. – 4 p.m.',
		   },
		   {
			  'days': 'Sunday',
			  'times': 'Closed',
		   },
		],
		'contact': {
		   'phone': '978.670.1690',
		   'email': 'DeliveryTeamBoston@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board Boston Area Delivery Center',
		   'streetAddress': '1 Enterprise Road, Suite 100',
		   'city': 'Billerica',
		   'state': 'MA',
		   'zipCode': '01821',
		   'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@42.5208082,-71.2309838,17z/data=!3m1!4b1!4m5!3m4!1s0x89e39f9a65363fd1:0x3b3099ede2892432!8m2!3d42.5208043!4d-71.2287951',
		},
		'schema': {
		   'openingHours': ['Mo-Sa 09:00-16:00'],
		},
		'key': 'boston-area-delivery-center',
	},
	chicago: {
		'title': 'Chicago Area Delivery Center',
		'hours': [
		   {
			  'days': 'Monday – Saturday',
			  'times': '9 a.m. – 4 p.m.',
		   },
		   {
			  'days': 'Sunday',
			  'times': 'Closed',
		   },
		],
		'contact': {
		   'phone': '630.645.6830',
		   'email': 'DeliveryTeamChicago@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board Chicago Area Delivery Center',
		   'streetAddress': '2525 West 22nd Street',
		   'city': 'Oak Brook',
		   'state': 'IL',
		   'zipCode': '60523',
		   'mapLink': 'https://www.google.com/maps/place/Room+%26+Board+Chicago+Area+Delivery+Center/@41.8450091,-87.9815892,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xa1125d56fa9f75e1!8m2!3d41.8450091!4d-87.9794005',
		},
		'schema': {
		   'openingHours': ['Mo-Sa 09:00-16:00'],
		},
		'key': 'chicago-area-delivery-center',
	},
	dallas: {
		'title': 'Dallas Area Delivery Center',
		'hours': [
			{
				'days': 'Tuesday – Saturday',
				'times': '9 a.m. – 4 p.m.',
			},
			{
				'days': 'Sunday – Monday',
				'times': 'Closed',
			},
		],
		'contact': {
		   'phone': '469.862.5659',
		   'email': 'DeliveryTeamDallas@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board Dallas Area Delivery Center',
		   'streetAddress': '11925 North Stemmons Freeway, Suite 160',
		   'city': 'Dallas',
		   'state': 'TX',
		   'zipCode': '75234',
		   'mapLink': 'https://www.google.com/maps/place/Room+%26+Board+Dallas+Delivery+Center/@32.9103607,-96.9036198,15z/data=!4m5!3m4!1s0x0:0xdaa9e459801648a9!8m2!3d32.9103607!4d-96.9036198?hl=en',
		},
		'schema': {
		   'openingHours': ['Tu-Sa 09:00-16:00'],
		},
		'key': 'dallas-area-delivery-center',
		'type': 'pickup',
	},
	denver: {
		'title': 'Denver Area Delivery Center',
		'hours': [
		   {
			  'days': 'Monday – Saturday',
			  'times': '9 a.m. – 5 p.m.',
		   },
		   {
			  'days': 'Sunday',
			  'times': 'Closed',
		   },
		],
		'contact': {
		   'phone': '303.778.1141',
		   'email': 'DeliveryTeamDenver@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board Denver Area Delivery Center',
		   'streetAddress': '6951 South Blackhawk Street, Unit 900',
		   'city': 'Centennial',
		   'state': 'CO',
		   'zipCode': '80112',
		   'mapLink': 'https://goo.gl/maps/1iAk7frSdJy',
		},
		'schema': {
		   'openingHours': ['Mo-Sa 09:00-16:00'],
		},
	},
	losAngelesArea: {
		'title': 'Los Angeles Area Delivery Center',
		'contact': {
			'phone': '310.326.0633',
			'email': 'DeliveryTeamLosAngeles@roomandboard.com',
		},
		'hours': [
			{
				'days': 'Monday – Saturday',
				'times': '9 a.m. – 5 p.m.',
			},
			{
				'days': 'Sunday',
				'times': 'Closed',
			},
		],
		'address': {
			'streetAddress': '1390 Storm Parkway',
			'city': 'Torrance',
			'state': 'CA',
			'zipCode': '90501',
			'mapLink': 'https://www.google.com/maps/place/Room+%26+Board+Southern+California+Delivery+Center/@33.818052,-118.3043415,17z/data=!3m1!4b1!4m5!3m4!1s0x80dd4a8295e94f77:0x51ad77279973a967!8m2!3d33.8180476!4d-118.3021528',
		},
		'schema': {
			'openingHours': ['Mo-Sa 09:00-17:00'],
		},
	},
	mplsStp: {
		'title': 'Minneapolis/St. Paul Area Delivery Center',
		'contact': {
			'phone': '763.521.4431',
			'email': 'DeliveryTeamMinneapolisNational@roomandboard.com',
		},
		'hours': [
			{
				'days': 'Monday – Friday',
				'times': '9 a.m. – 6 p.m.',
			},
			{
				'days': 'Saturday',
				'times': '9 a.m. – 5 p.m.',
			},
			{
				'days': 'Sunday',
				'times': 'Closed',
			},
		],
		'schema': {
			'openingHours': ['Mo-Fr 09:00-18:00', 'Sa 09:00-17:00'],
		},
		'address': {
			'streetAddress': '4600 Olson Memorial Highway',
			'city': 'Golden Valley',
			'state': 'MN',
			'zipCode': '55422',
			'mapLink': 'https://www.google.com/maps/place/Room+%26+Board+Minneapolis%2FSt.+Paul+Area+Delivery+Center/@44.9857555,-93.3375618,228m/data=!3m2!1e3!4b1!4m5!3m4!1s0x52b333793b30f1b7:0x56ff86390344e668!8m2!3d44.9857545!4d-93.3370086',
		},
	},
	newYorkArea: {
		'title': 'New York Area Delivery Center',
		'hours': [
			{
				'days': 'Monday – Saturday',
				'times': '9 a.m. – 4 p.m.',
			},
			{
				'days': 'Sunday',
				'times': 'Closed',
			},
		],
		'contact': {
		   'phone': '201.440.4221',
		   'email': 'DeliveryTeamNewYork@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board New York Area Delivery Center',
		   'streetAddress': '9 Garden Street',
		   'city': 'Moonachie',
		   'state': 'NJ',
		   'zipCode': '07074',
		   'mapLink': 'https://www.google.com/maps/place/Room+%26+Board+New+York+Area+Delivery+Center/@40.837535,-74.0498297,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2f836b2b96f69:0xc543045120c8804b!8m2!3d40.837531!4d-74.047641',
		},
		'schema': {
		   'openingHours': ['Mo-Sa 09:00-16:00'],
		},
	},
	portland: {
		'title': 'Portland Area Delivery Center',
		'hours': [
			{
				 'days': 'Tuesday – Saturday',
				 'times': '9 a.m. – 4 p.m.',
			},
			{
				 'days': 'Sunday – Monday',
				 'times': 'Closed',
			},
		],
		'contact': {
		   'phone': '503.486.6950',
		   'email': 'DeliveryTeamPortland@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board Portland Area Delivery Center',
		   'streetAddress': '20850 Southwest 115th Avenue, Suite 100',
		   'city': 'Tualatin',
		   'state': 'OR',
		   'zipCode': '97062',
		   'mapLink': 'https://www.google.com/maps/place/Room+%26+Board+Portland+Area+Delivery+Center/@45.3695589,-122.7945063,17z/data=!4m12!1m6!3m5!1s0x54956d7475241943:0xf984b498f96362f!2sRoom+%26+Board+Delivery+Center!8m2!3d45.3695552!4d-122.7923176!3m4!1s0x0:0x2a35767e09ff4b4f!8m2!3d45.3695554!4d-122.7923173',
		},
		'schema': {
		   'openingHours': ['Tu-Sa 09:00-16:00'],
		},
	},
	sanDiego: {
		'title': 'San Diego Area Delivery Center',
		'hours': [
			{
				 'days': 'Tuesday – Saturday',
				 'times': '9 a.m. – 4 p.m.',
			},
			{
				 'days': 'Sunday – Monday',
				 'times': 'Closed',
			},
		],
		'contact': {
		   'phone': '760.494.9960',
		   'email': 'DeliveryTeamSanDiego@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board San Diego Area Delivery Center',
		   'streetAddress': '6131 Innovation Way',
		   'city': 'Carlsbad',
		   'state': 'CA',
		   'zipCode': '92009',
		   'mapLink': 'https://www.google.com/maps/place/Room+%26+Board+San+Diego+Area+Delivery+Center/@33.1289951,-117.2625958,17z/data=!4m13!1m7!3m6!1s0x80dc74a20cf2c9b7:0x8f37f51d2eaf3c5c!2s6131+Innovation+Way,+Carlsbad,+CA+92009!3b1!8m2!3d33.1286641!4d-117.2593208!3m4!1s0x80dc74a26684ba25:0x8c7c10a3dacfe05c!8m2!3d33.1289147!4d-117.2605579',
		},
		'schema': {
		   'openingHours': ['Tu-Sa 09:00-16:00'],
		},
	},
	seattle: {
		'title': 'Seattle Area Delivery Center',
		'hours': [
			  {
				 'days': 'Monday – Saturday',
				 'times': '9 a.m. – 4 p.m.',
			  },
			  {
				 'days': 'Sunday',
				 'times': 'Closed',
			  },
		   ],
		'contact': {
		   'phone': '253.398.1949',
		   'email': 'DeliveryTeamSeattle@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board Seattle Area Delivery Center',
		   'streetAddress': '19920 66th Ave South, Suite 100',
		   'city': 'Kent',
		   'state': 'WA',
		   'zipCode': '98032',
		   'mapLink': 'https://goo.gl/maps/5CLewcPVZQ9ayzGU9',
		},
		'schema': {
		   'openingHours': ['Mo-Sa 09:00-16:00'],
		},
	},
	sanFrancisco: {
		'title': 'San Francisco Area Delivery Center',
		'hours': [
			  {
				 'days': 'Monday – Saturday',
				 'times': '9 a.m. – 4 p.m.',
			  },
			  {
				 'days': 'Sunday',
				 'times': 'Closed',
			  },
		   ],
		'contact': {
		   'phone': '415.468.6300',
		   'email': 'DeliveryTeamSanFrancisco@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board San Francisco Area Delivery Center',
		   'streetAddress': '450 Valley Drive',
		   'city': 'Brisbane',
		   'state': 'CA',
		   'zipCode': '94005',
		   'mapLink': 'https://www.google.com/maps/place/Room+%26+Board/@37.6913802,-122.4175987,17z/data=!3m1!4b1!4m5!3m4!1s0x808f7931a6b34177:0xa92ace4ad297e212!8m2!3d37.691376!4d-122.41541',
		},
		'schema': {
		   'openingHours': ['Mo-Sa 09:00-16:00'],
		},
	},
	washingtonDc: {
		'title': 'Washington, D.C. Area Delivery Center',
		'hours': [
		   {
			  'days': 'Monday – Saturday',
			  'times': '9 a.m. – 4 p.m.',
		   },
		   {
			  'days': 'Sunday',
			  'times': 'Closed',
		   },
		],
		'contact': {
		   'phone': '410.796.6181',
		   'email': 'DeliveryTeamWashingtonDC@roomandboard.com',
		},
		'address': {
		   'title': 'Room & Board Washington, D.C. Area Delivery Center',
		   'streetAddress': '7045 Troy Hill Drive, Suite 500',
		   'city': 'Elkridge',
		   'state': 'MD',
		   'zipCode': '21075',
		   'mapLink': 'https://www.google.com/maps?cid=17987727061642567421',
		},
		'schema': {
		   'openingHours': ['Mo-Sa 09:00-16:00'],
		},
		'key': 'washington-dc-area-delivery-center',
		'type': 'pickup',
	 },
};
