import React from 'react';

import { sitePath } from '~/global/global.constants';
import { PrimaryLayout } from '~/layout/Components/PrimaryLayout';
import Head from '~/layout/head/Components/Head';

interface Props {
	_links?: any
}

export const NotFound = (props: Props) => {
	const {
		_links: {
			subcategory = '',
			category = '',
		} = {},
	} = props || {};

	const subcategoryOrCategoryLink = subcategory || category || {};

	const { href, title } = subcategoryOrCategoryLink;

	return (
		<PrimaryLayout>
			<Head
				title="Information Not Found"
				errorStatusCode="404"
			/>
			<div className="not-found tw-py-[20px]">
				<div className="tw-grid tw-grid-cols-12">
					<div className="tw-col-span-12 md:tw-col-span-9">
						<h2 className="Heading Heading--2 tw-text-gray tw-mb-[20px]">
							Sorry, the product or page you requested has moved or is no longer available.
						</h2>

						{
							href && title &&
							<p>
								You may be interested in other <a href={`${sitePath}/${href.toString().replace(/^\/+/, '')}`}>{title}</a>
							</p>
						}

						<p>
							If you’d like assistance from a Design Associate, contact us by phone at <a href="tel:+18003019720">800.301.9720</a> or by email at <a href="mailto:shop@roomandboard.com">shop@roomandboard.com</a>.
						</p>

						<p>
							<a href="/">
								Return to Homepage
							</a>
						</p>
					</div>

				</div>
			</div>
		</PrimaryLayout>
	);
};
